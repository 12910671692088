import React from 'react';

import { graphql } from 'gatsby';

import CommonProductPageTemplate from './ProductPageTemplate';
import AnoraProProductPageTemplate from './anoraPro';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function ProductPageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProProductPageTemplate {...props} />;
  }
  return <CommonProductPageTemplate {...props} />;
}

export const pageQuery = graphql`
  query ProductPageTemplate($pageId: String!) {
    magentoProduct(id: { eq: $pageId }) {
      id
      name
      sku
      price
      fullPath
      languageCode
      node_locale
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      relatedArticlePages {
        ...RelatedArticlePageFragment
      }
      relatedRecipePages {
        ...RelatedRecipePageFragment
      }
      heroImage {
        ...ProductImageFragment
      }
      attributes {
        ...ProductAttributesFragment
      }
      brands {
        ...ProductBrandFragment
      }
      categories {
        ...ProductCategoryFragment
      }
      countryCategories {
        ...ProductCategoryFragment
      }
      foodRecommendationCategories {
        ...ProductCategoryFragment
      }
      tasteStyleCategories {
        ...ProductCategoryFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default ProductPageTemplate;
