import React from 'react';
import PropTypes from 'prop-types';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import ProductPageTemplate from '../ProductPageTemplate';

function AnoraProProductPageTemplate({ ...props }) {
  return <ProductPageTemplate {...props} cleanPrint />;
}

AnoraProProductPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProProductPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withAuthenticationRequired(AnoraProProductPageTemplate);
